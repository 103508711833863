import React, { useEffect, useState } from "react";
import { Row, Col, Modal, Button, Image, Space, Tooltip, Tag, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import ViewCustomized from "../../assets/images/icons/ViewCustomized.png";
import { showFilterModal, hideFilterModal } from "../../redux/filterModal";
import FilterModal from ".././FilterModal";
import Monthwise from "./Monthwise";
import { useLocation } from "react-router-dom";
import { URL } from "../Helper/MainURL";
import PapaParse from "papaparse";
import { DownloadOutlined } from "@ant-design/icons";

const Label = ["", "Promo by brand"];

const PromotionsTrends = (props) => {
  const location = useLocation();
  const { isModalOpen } = useSelector((state) => state.filtermodal);
  const filterValues = useSelector((state) => state.filterValue);
  const [activeTab, setActiveTab] = useState(location.state?.activeTab || 1);

  const [promoData, setPromoData] = useState([]);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(null);
  const [modalflag, setModalFlag] = useState(false);
  const [img, setimg] = useState("");
  const [ImageName, setImageName] = useState("");
  const [loader5, setloader5] = useState(false);
  const { loader } = useSelector((state) => state.loader);
  const [calApi, setCalApi] = useState(0);

  const [sorter, setSorter] = useState({
    columnKey: "Title",
    order: "ascend",
  });
  const { SourceType, Timescales, TimescalesTrend, MarketSegment, CompetitiveSet, Category, PromoType, PromoType2, TimePeriodPromo } = filterValues;
  const dispatch = useDispatch();
  useEffect(() => {
    setCurrentPage(1);
    getPromotionsData();
  }, [calApi]);
  useEffect(() => {
    getPromotionsData();
  }, [currentPage, sorter]);

  const showModal = () => {
    dispatch(showFilterModal());
  };
  const handleOk = () => {
    dispatch(hideFilterModal());
  };
  const handleCancel = () => {
    dispatch(hideFilterModal());
  };

  const getPromotionsData = async () => {
    setloader5(true);

    let payLoad = {
      page_number: currentPage,
      filters: {
        Timescales: activeTab == 1 ? filterValues.TimescalesTrend : filterValues.Timescales,
        Market_Segment: filterValues.MarketSegment,
        Competitive_Set: filterValues.CompetitiveSet,
        Category: filterValues.Category,
        Source_Type: filterValues.SourceType,
        Promo_Type2: filterValues.PromoType2,
        Promo_Type: filterValues.PromoType,
      },
     TimePeriodPromo:filterValues.TimePeriodPromo,
      sort_column: sorter.columnKey,
      sort_type: sorter.order == "ascend" ? "ASC" : "DESC",
      email: localStorage.getItem("email"),
    };
    const randomParam = Math.random().toString(36).substring(7);
    await fetch(`${URL}/promotion-line-month/?${randomParam}`, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payLoad),
    })
      .then(function (response) {
        response.json().then(function (data) {
          setPromoData(data?.data);
          setTotalPage(data?.total_count);
          setData(data);

          setloader5(false);
        });
      })
      .catch((error) => {
        console.error("Fetch error:", error);
      });
  };

  const getDownloadData = (data) => {
    // setloadingExport(true);
    downloadcsv(data);
    console.log("downloadcsv::::::::",data)
  };
  const downloadcsv = (data) => {
    console.log("data-----",data)
    // return
      let LastLine1={FormattedDate:"Copyright 2025. Meaningful Vision . All Rights Reserved. Proprietary and Confidential"}
      data.data.push(LastLine1)
      let LastLine2={FormattedDate:"Property of Meaningful Vision  and it's Affiliates. Licensed for Use by Meaningful Vision  Clients Only."}
      data.data.push(LastLine2)
    
   
    // setloadingExport(false);
    var csv = PapaParse.unparse(data);
    var csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    var csvURL = null;
    csvURL = window.URL.createObjectURL(csvData);
    var tempLink = document.createElement("a");
    tempLink.href = csvURL;
    tempLink.setAttribute("download",  "promotion-trends");
    tempLink.click();
  };

  return (
    <>
      <>
        <Row className='mb-2'>
          <Col span={8} className='p-1'>
            <span className='bg-white text-dark p-2 rounded'>
              <span className='fw-bold page-header' style={{ fontFamily: "Quicksand" }}>
                {Label[activeTab]}
              </span>{" "}
              <span style={{ color: "#1DB9DE" }} className='fw-bold'>
                |
              </span>{" "}
              <span className='page-header-table' style={{ fontFamily: "Quicksand" }}>
                {activeTab == 1 ? "Chart" : "Chart"}
              </span>
            </span>
          </Col>
          <Col span={12} className='text-center'></Col>
          <Col span={4} className='p-1 text-center'>
            <Button onClick={showModal} className='mb-4' icon={<Image src={ViewCustomized} width={14} preview={false} />} size={"large"}>
              Filters
            </Button>
            {" "}
          <Button
                block
                className='mb-4 export-button '
                icon={<DownloadOutlined />}
                size={"large"}
                // loading={loadingExport}
                onClick={() => {
                 getDownloadData(data);
                }}
              ></Button>
          </Col>
        </Row>
        <Row className='mb-4'>
          <Space size={[0, "small"]} wrap>
          <Tooltip
              placement='bottomLeft'
              title={
                TimePeriodPromo && TimePeriodPromo.length > 0
                  ? TimePeriodPromo.map((e) => {
                      return <li>{e}</li>;
                    })
                  : "Not selected"
              }
            >
              <Tag bordered={false} size='large' className='my-custom-tag tag-csss' style={{ borderRadius: "10px" }}>
                Time Period:
                {TimePeriodPromo && TimePeriodPromo.length > 0 ? `${TimePeriodPromo[0]} ` : "Not selected"}{" "}
              </Tag>
            </Tooltip>
            {activeTab == 1 && (
              <Tooltip placement='bottomLeft' title={TimescalesTrend && TimescalesTrend.length > 0 ? TimescalesTrend.join("").substring(0, 10) + " - " + TimescalesTrend.join("").substring(10, 20) : " All"}>
                <Tag bordered={false} size='large' className='my-custom-tag tag-csss' style={{ borderRadius: "10px" }}>
                  Timescales:
                  {TimescalesTrend && TimescalesTrend.length > 0 ? TimescalesTrend.join("").substring(0, 10) + " - " + TimescalesTrend.join("").substring(10, 20) : "Not selected"}{" "}
                </Tag>
              </Tooltip>
            )}
            {activeTab != 1 && (
              <Tooltip placement='bottomLeft' title={Timescales && Timescales.length > 0 ? Timescales.join("").substring(0, 10) + " - " + Timescales.join("").substring(10, 20) : " All"}>
                <Tag bordered={false} size='large' className='my-custom-tag tag-csss' style={{ borderRadius: "10px" }}>
                  Timescales:
                  {Timescales && Timescales.length > 0 ? Timescales.join("").substring(0, 10) : "Not selected"}{" "}
                </Tag>
              </Tooltip>
            )}
            <Tooltip
              placement='bottomLeft'
              title={
                MarketSegment && MarketSegment.length > 0
                  ? MarketSegment.map((e) => {
                      return <li>{e}</li>;
                    })
                  : "Not selected"
              }
            >
              <Tag bordered={false} size='large' className='my-custom-tag tag-csss' style={{ borderRadius: "10px" }}>
                Segment:
                {MarketSegment && MarketSegment.length > 0 ? `${MarketSegment[0]} ${MarketSegment?.length ? MarketSegment.length : ""} ` : "Not selected"}{" "}
              </Tag>
            </Tooltip>
            <Tooltip
              placement='bottomLeft'
              title={
                CompetitiveSet && CompetitiveSet.length > 0
                  ? CompetitiveSet.map((e) => {
                      return <li>{e}</li>;
                    })
                  : "Not selected"
              }
            >
              <Tag bordered={false} size='large' className='my-custom-tag tag-csss' style={{ borderRadius: "10px" }}>
                Competitive:
                {CompetitiveSet && CompetitiveSet.length > 0 ? `${CompetitiveSet[0]} ${CompetitiveSet?.length > 1 ? CompetitiveSet.length : ""} ` : "Not selected"}{" "}
              </Tag>
            </Tooltip>
            <Tooltip
              placement='bottomLeft'
              title={
                Category && Category.length > 0
                  ? Category.map((e) => {
                      return <li>{e}</li>;
                    })
                  : "Not selected "
              }
            >
              <Tag bordered={false} size='large' className='my-custom-tag tag-csss' style={{ borderRadius: "10px" }}>
                Category:
                {Category && Category.length > 0 ? `${Category[0]} ` : " Not selected"}{" "}
              </Tag>
            </Tooltip>

            <Tooltip
              placement='bottomLeft'
              title={
                SourceType && SourceType.length > 0
                  ? SourceType.map((e) => {
                      return <li>{e}</li>;
                    })
                  : "Not selected"
              }
            >
              <Tag bordered={false} size='large' className='my-custom-tag tag-csss' style={{ borderRadius: "10px" }}>
                Channel:
                {SourceType && SourceType.length > 0 ? `${SourceType[0]} ` : "Not selected"}{" "}
              </Tag>
            </Tooltip>
            <Tooltip
              placement='bottomLeft'
              title={
                PromoType && PromoType.length > 0
                  ? PromoType.map((e) => {
                      return <li>{e}</li>;
                    })
                  : "Not selected"
              }
            >
              <Tag bordered={false} size='large' className='my-custom-tag tag-csss' style={{ borderRadius: "10px" }}>
                Promo Type:
                {PromoType && PromoType.length > 0 ? `${PromoType[0]} ` : "Not selected"}{" "}
              </Tag>
            </Tooltip>
            <Tooltip
              placement='bottomLeft'
              title={
                PromoType2 && PromoType2.length > 0
                  ? PromoType2.map((e) => {
                      return <li>{e}</li>;
                    })
                  : "Not selected"
              }
            >
              <Tag bordered={false} size='large' className='my-custom-tag tag-csss' style={{ borderRadius: "10px" }}>
                Promo Type2:
                {PromoType2 && PromoType2.length > 0 ? `${PromoType2[0]} ` : "Not selected"}{" "}
              </Tag>
            </Tooltip>
           
          </Space>
        </Row>

        {loader5 && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 1000,
            }}
          >
            <Spin></Spin>
          </div>
        )}
        <Monthwise promoData={data} />
        <Modal
          open={modalflag}
          justifyContent='center'
          destroyOnClose
          onCancel={(e) => {
            setModalFlag(false);
          }}
          footer={false}
          size='large'
          width={600}
          height={600}
          title={<div className='text-left fs-6'>{ImageName}</div>}
        >
          <div style={{ display: "flex", justifyContent: "center", minHeight: "300px" }}>
            <img src={`https://mvtool.s3.eu-west-2.amazonaws.com/images/promo/${img}`} width='300' height='300px' />
          </div>
        </Modal>
        <FilterModal showModal={showModal} isModalOpen={isModalOpen} handleCancel={handleCancel} handleOk={handleOk} setCalApi={setCalApi} calApi={calApi} name={"Promo"} activePromo1={5} PromotionsTrendsflag={"PromotionsTrendsflag"} dashboard={"Promotions"}/>
      </>
    </>
  );
};
export default PromotionsTrends;
