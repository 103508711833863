import { createSlice } from "@reduxjs/toolkit";
function getLast12MonthsTimescale() { 
  // const dateString = "Tue Oct 1 2024 16:44:15 GMT+0530 (India Standard Time)";
  const dateString = "Fri Jan 31 2025 00:00:00 GMT+0530 (India Standard Time)";
  const today = new Date(dateString);
  const timescale = [];

  for (let i = 12; i >= 0; i--) {
    const date = new Date(today.getFullYear(), today.getMonth() - i, 1);
    timescale.push(formatDate(date));
  }

  return [timescale[0], timescale[11]];
}

function formatDate(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  return `${year}-${month}-01`;
}

const last12MonthsTimescale = getLast12MonthsTimescale();
// Get today's date
const today = new Date();

// Subtract one month from today's date
const lastMonth = new Date(today);
lastMonth.setMonth(lastMonth.getMonth() - 1);

let country;


if (localStorage.getItem("organization") === "Coca Cola UK") {
  country = "Northern Ireland";
} else if (localStorage.getItem("country") === "UK") {
  country = "UK";
} else if (localStorage.getItem("country") === "Ireland") {
  country = "Ireland";
}

export const filterValues = createSlice({
  name: "filterValue",
  initialState: {
    Timescales: ["Dec-24"],
    TimescalesTrend: [...last12MonthsTimescale],
    MarketSegment: [],
    CompetitiveSet: [],
    Category: [],
    ProteinType: [],
    Channel: ["All"],
    PriceRange: [],
    City: ["All"],
    Item: [],
    ProductSize: [],
    ComparisionType: ["Vs Last Year"],
    SourceType: [],
    PromoType: [],
    PromoType2: [],
    Region: [country], // for visit region
    DayTime: ["All"],
    TimePeriod: ["Monthly"],
    TimePeriodPromo: ["Monthly"],
    TimescalesWeekly: ["2024-12-30"],
    TimePeriodOpenings: ["Monthly"],
    RegionOpenings: ["Total UK"],
    RegionValue: ["Total UK"],
    region: ["Total UK"],
    cityRegion:["All"],
    PromoTypeLength: [],
    Offer: [],  
    PriceSegment:[],
    benchmark:["All"],
    loading: false,
  },
  reducers: {
    setFilterValue: (state, action) => {
      // console.log(':::::::state::::::::',state)

      const {
        Timescales,
        MarketSegment,
        CompetitiveSet,
        Category,
        ProteinType,
        Channel,
        PriceRange,
        City,
        Item,
        TimescalesTrend,
        ProductSize,
        ComparisionType,
        SourceType,
        PromoType,
        PromoType2,
        Region,
        DayTime,
        TimePeriod,
        TimePeriodPromo,
        PromoTypeLength,
        flagForBarChart,
        Offer,
        TimescalesWeekly,
        TimePeriodOpenings,
        RegionOpenings,
        region,
        cityRegion,
        PriceSegment,
        benchmark
      } = action.payload;
      // console.log(':::::::action::::::::',action.payload)
      // console.log(':::::::action region::::::::',region)
      //redux-persist package   https://www.npmjs.com/package/redux-persist
      if (Timescales) {
        localStorage.setItem("Timescales", Timescales);
      }
      if (MarketSegment) {
        localStorage.setItem("MarketSegment", MarketSegment);
      } else {
        localStorage.setItem("MarketSegment", []);
      } if (City) {
        localStorage.setItem("City", City);
      } else {
        localStorage.setItem("City", []);
      }
      if (cityRegion) {
        localStorage.setItem("cityRegion", cityRegion);
      } else {
        localStorage.setItem("cityRegion", []);
      }
      if (region) {
        localStorage.setItem("region", region);
      } else {
        localStorage.setItem("region", []);
      }
      if (CompetitiveSet) {
        localStorage.setItem("CompetitiveSet", CompetitiveSet);
      }
      if (Category) {
        localStorage.setItem("Category", Category);
      } else {
        localStorage.setItem("Category", []);
      }
      if (SourceType) {
        localStorage.setItem("SourceType", SourceType);
      } else {
        localStorage.setItem("SourceType", []);
      }
      if (PromoType) {
        localStorage.setItem("PromoType", PromoType);
      } else {
        localStorage.setItem("PromoType", []);
      }
      if (PromoType2) {
        localStorage.setItem("PromoType2", PromoType2);
      } else {
        localStorage.setItem("PromoType2", []);
      }
      if (TimePeriodPromo) {
        localStorage.setItem("TimePeriodPromo", TimePeriodPromo);
      }
      if (TimescalesWeekly) {
        localStorage.setItem("TimescalesWeekly", TimescalesWeekly);
      }
      if (ProteinType) {
        localStorage.setItem("ProteinType", ProteinType);
      } else {
        localStorage.setItem("ProteinType", []);
      }
      if (Offer) {
        localStorage.setItem("Offer", Offer);
      } else {
        localStorage.setItem("Offer", []);
      }
      if (PriceSegment) {
        localStorage.setItem("PriceSegment", PriceSegment);
      } else {
        localStorage.setItem("PriceSegment", []);
      }if (benchmark) {
        localStorage.setItem("benchmark", benchmark);
      } else {
        localStorage.setItem("benchmark", []);
      }
      
      state.Timescales = Timescales ? Timescales : ["Dec-24"];
      state.MarketSegment = MarketSegment;
      state.CompetitiveSet = CompetitiveSet;
      state.Category = Category;
      state.ProteinType = ProteinType;
      state.Channel = Channel ? Channel : ["All"];
      state.PriceRange = PriceRange;
      state.City = City ? City : [];
      state.Item = Item;
      state.TimescalesTrend = TimescalesTrend ? TimescalesTrend : [];
      state.ProductSize = ProductSize ? ProductSize : [];
      state.ComparisionType = ComparisionType ? ComparisionType : [];
      state.PromoType = PromoType ? PromoType : [];
      state.PromoType2 = PromoType2 ? PromoType2 : [];
      state.SourceType = SourceType ? SourceType : [];
      state.DayTime = DayTime ? DayTime : ["All"];
      state.TimePeriod = TimePeriod ? TimePeriod : ["Monthly"];
      state.TimePeriodPromo = TimePeriodPromo ? TimePeriodPromo : ["Monthly"];
      state.TimePeriodOpenings = TimePeriodOpenings ? TimePeriodOpenings : ["Monthly"];
      state.TimescalesWeekly = TimescalesWeekly ? TimescalesWeekly : ["2024-12-30"];
      state.Region = Region ? Region : [country];
      state.RegionOpenings = RegionOpenings ? RegionOpenings : ["Total UK"];
      state.PromoTypeLength = PromoTypeLength ? PromoTypeLength : [];
      state.flagForBarChart = flagForBarChart ? flagForBarChart : false;
      state.Offer = Offer ? Offer : [];
      state.region = region ? region : ["Total UK"];
      state.cityRegion = cityRegion ? cityRegion : ["All"];
      state.PriceSegment=PriceSegment? PriceSegment:[]
      state.benchmark=benchmark?benchmark:["All"]
    },
  },
});

export const { setFilterValue, setLoading } = filterValues.actions;

export default filterValues.reducer;
