import { useEffect, useState } from "react";
import { Row, Space, Tooltip, Tag, Col, Image, Button } from "antd";
import { useSelector, useDispatch } from "react-redux";
import ViewCustomized from "../../assets/images/icons/ViewCustomized.png";
import { DownloadOutlined } from "@ant-design/icons";
import OpeningBar from "./OpeningBar";
import { showFilterModal, hideFilterModal } from "../../redux/filterModal";
import FilterModal from "../FilterModal";
import PapaParse from "papaparse";
import { URL } from "../Helper/MainURL";

const SnapshotOpening = () => {
  const filterValues = useSelector((state) => state.filterValue);
  const { Timescales, MarketSegment, CompetitiveSet, Region, City,region , cityRegion } = filterValues;
  const [calApi, setCalApi] = useState(0);
  const [data, setData] = useState([]);
  const [loadingExport] = useState(false);
  const { isModalOpen } = useSelector((state) => state.filtermodal);
  const dispatch = useDispatch();
  const showModal = () => {
    dispatch(showFilterModal());
  };
  const handleOk = () => {
    dispatch(hideFilterModal());
  };
  const handleCancel = () => {
    dispatch(hideFilterModal());
  };
  useEffect(() => {
    getData(filterValues);
  }, [calApi]);

  const getData = async (filterValues) => {
    let payload = {
      page_number: 1,
      filters: {
        TimeScale: filterValues.Timescales,
        Market_Segment: filterValues.MarketSegment,
        Competitive_Set: filterValues.CompetitiveSet,
        Region: filterValues.region,
        cityRegion:  filterValues?.cityRegion,
      },
      sort_column: "Competitive_Set",
      sort_type: "ASC",
      comparison_type: ["No Comparison"],
      data_type: 1,

      email: localStorage.getItem("email"),
      country: localStorage.getItem("country"),
    };
    try {
      await fetch(`${URL}/openings/openings-snapshot`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        cache: "default",

        body: JSON.stringify(payload),
      }).then(function (response) {
        response.json().then(function (data) {
          setData(data);
        });
      });
    } catch (e) {
      console.log("Error in Openings->Snapshot");
    }
    return;
  };
  const getDownloadData = (data) => {
    // openNotification('topRight');
    // setloadingExport(true);
    downloadcsv(data);
  };
  const downloadcsv = (data) => {
   
    
    let LastLine1={BrandName:"Copyright 2025.   Meaningful Vision. All Rights Reserved. Proprietary and Confidential"}
    data.data.push(LastLine1)
    let LastLine2={BrandName:"Property of Meaningful Vision and it's Affiliates. Licensed for Use by Meaningful Vision Clients Only."}
    data.data.push(LastLine2)
    var csv = PapaParse.unparse(data);
    var csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    var csvURL = null;
    csvURL = window.URL.createObjectURL(csvData);
    var tempLink = document.createElement("a");
    tempLink.href = csvURL;
    tempLink.setAttribute("download", `Top growing chains.csv`);
    tempLink.click();
  };
  return (
    <>
      <Row>
        <Col span={8} className='p-1'>
          <span className='bg-white text-dark p-2 rounded'>
            <span className='fw-bold page-header' style={{ fontFamily: "Quicksand" }}>
              Top growing chains
            </span>{" "}
            <span style={{ color: "#1DB9DE" }} className='fw-bold'>
              {" "}
              |
            </span>{" "}
            <span className='page-header-table' style={{ fontFamily: "Quicksand" }}>
              Table
            </span>
          </span>
        </Col>
        <Col span={8} className='text-left'></Col>
        <Col span={3}></Col>

        <Col span={5} className=' text-center'>
          <Space>
            <Button block onClick={showModal} className='mb-4' icon={<Image src={ViewCustomized} width={14} preview={false} />} size={"large"}>
              Filters
            </Button>
            <Button block className='mb-4 export-button ' icon={<DownloadOutlined />} size={"large"} loading={loadingExport} onClick={() => {  getDownloadData(data)}}></Button>
          </Space>
        </Col>
      </Row>
      <Row className='mb-4'>
        <Space size={[0, "small"]} wrap>
          <Tooltip placement='bottomLeft' title={Timescales && Timescales.length > 0 ? Timescales.join("").substring(0, 10) : " All"}>
            <Tag bordered={false} size='large' className='my-custom-tag tag-csss' style={{ borderRadius: "10px" }}>
              Timescales:
              {Timescales && Timescales.length > 0 ? Timescales.join("").substring(0, 10) : "Not selected"}{" "}
            </Tag>
          </Tooltip>
          <Tooltip
            placement='bottomLeft'
            title={
              MarketSegment && MarketSegment.length > 0
                ? MarketSegment.map((e) => {
                    return <li>{e}</li>;
                  })
                : "Not selected"
            }
          >
            <Tag bordered={false} size='large' className='my-custom-tag tag-csss' style={{ borderRadius: "10px" }}>
              Segment:
              {MarketSegment && MarketSegment.length > 0 ? `${MarketSegment[0]} ${MarketSegment?.length ? MarketSegment.length : ""} ` : "Not selected"}{" "}
            </Tag>
          </Tooltip>
          <Tooltip
            placement='bottomLeft'
            title={
              CompetitiveSet && CompetitiveSet.length > 0
                ? CompetitiveSet.map((e) => {
                    return <li>{e}</li>;
                  })
                : "Not selected"
            }
          >
            <Tag bordered={false} size='large' className='my-custom-tag tag-csss' style={{ borderRadius: "10px" }}>
              Competitive:
              {CompetitiveSet && CompetitiveSet.length > 0 ? `${CompetitiveSet[0]} ${CompetitiveSet?.length > 1 ? CompetitiveSet.length : ""} ` : "Not selected"}{" "}
            </Tag>
          </Tooltip>
          <Tooltip
            placement='bottomLeft'
            title={
              region && region.length > 0
                ? region.map((e) => {
                    return <li>{e}</li>;
                  })
                : "Not selected"
            }
          >
            <Tag bordered={false} size='large' className='my-custom-tag tag-csss' style={{ borderRadius: "10px" }}>
              Region:
              {region && region.length > 0 ? `${region[0]}   ${region?.length > 1 ? region.length : " " } ` : "Not selected"}{" "}
            </Tag>
          </Tooltip>
          <Tooltip
            placement='bottomLeft'
            title={
              cityRegion && cityRegion.length > 0
                ? cityRegion.map((e) => {
                    return <li>{e}</li>;
                  })
                : "Not selected"
            }
          >
            <Tag bordered={false} size='large' className='my-custom-tag tag-csss' style={{ borderRadius: "10px" }}>
              City:
              {cityRegion && cityRegion.length > 0 ? `${cityRegion[0]}` : "Not selected"}{" "}
            </Tag>
          </Tooltip>
        </Space>
      </Row>
     <OpeningBar openingData={data} setActiveTab={1} filterValues1={JSON.parse(JSON.stringify(filterValues))} setCalApi={setCalApi} calApi={calApi} />
      {isModalOpen && <FilterModal showModal={showModal} isModalOpen={isModalOpen} handleCancel={handleCancel} handleOk={handleOk} setCalApi={setCalApi} calApi={calApi} name={"OpeningsSnapshot"} activePromo={1} dashboard={"Openings"} />}
    </>
  );
};

export default SnapshotOpening;
